import { Key } from "react";
import { IPaginatedGridResponse } from "./paginationResponse.types";
import { TUrl } from "./themeSettings/themeSettings.types";

export type TPhoto = {
  order: number;
  url?: TUrl;
};

export enum PIMInputTypes {
  pim_catalog_date = "pim_catalog_date",
  pim_catalog_file = "pim_catalog_file",
  pim_catalog_image = "pim_catalog_image",
  pim_catalog_metric = "pim_catalog_metric",
  pim_catalog_price_collection = "pim_catalog_price_collection",
  pim_catalog_multiselect = "pim_catalog_multiselect",
  pim_catalog_simpleselect = "pim_catalog_simpleselect",
  pim_catalog_textarea = "pim_catalog_textarea",
  pim_catalog_text = "pim_catalog_text",
  pim_catalog_number = "pim_catalog_number",
  pim_catalog_identifier = "pim_catalog_identifier",
  pim_catalog_boolean = "pim_catalog_boolean",
}

export enum PIMAttributeTypes {
  TEXT = "TEXT",
  DATE = "DATE",
  NUMBER = "NUMBER",
  BOOLEAN = "BOOLEAN",
  MULTISELECT = "MULTISELECT",
  SELECT = "SELECT",
}

export type TProduct = {
  badges: Record<Key, string>;
  basePrice: number;
  createdBy: string;
  creationDate: string;
  deliveryTime: string;
  enabled: boolean;
  family: string;
  id: string;
  image: TUrl;
  modificationDate: string;
  modifiedBy: string;
  photoGallery: TPhoto[];
  pools: Record<Key, string>;
  productName: string;
  sku: string;
  stock: number;
  visibility: boolean;
};

export type TCategory = {
  label: string;
  code: string;
  updated: string;
  parentCode: string;
  children: TCategory[];
};

export type TCategoryResponse = {
  id: string;
  name: string;
} & Partial<TCategory>;

export enum IntegerOperators {
  EQ = "EQ",
  NOT_EQ = "NOT_EQ",
  GTE = "GTE",
  LTE = "LTE",
  LT = "LT",
  GT = "GT",
  FROM_TO = "FROM_TO", // !! value doesnt exist in BE GRID documentation
}

export type TAttribute = {
  name: string;
  id: string;
  code: string;
  filterable: boolean;
  type: PIMInputTypes;
  label: string;
  group: string;
  plGroupLabel: string;
  usGroupLabel: string;
  sortOrder: number;
  localizable: boolean;
  scopable: boolean;
  availableLocales: string[];
  unique: boolean;
  useableAsGridFilter: boolean;
  maxCharacters: number;
  validationRule: string;
  validationRegexp: string;
  wysiwygEnabled: boolean;
  numberMin: string;
  numberMax: string;
  decimalsAllowed: boolean;
  negativeAllowed: boolean;
  metricFamily: string;
  defaultMetricUnit: string;
  dateMin: Date;
  dateMax: Date;
  allowedExtensions: string[];
  maxFileSize: string;
  referenceDataName: string;
  defaultValue: boolean;
  sortable: boolean;
  options: {
    code: string;
    attribute: string;
    sortOrder: unknown;
    label: string;
  }[];
};

export type TAttributeType = Partial<
  Record<
    PIMInputTypes,
    { fieldType: string; path: string; control: PIMAttributeTypes }
  >
>;

export type TPool = {
  id: number;
  version: number;
  name: string;
  badgeKind: string;
  modifiedBy: string;
  modificationDate: Date;
  createdBy: string;
  creationDate: Date;
  productCount: number;
};

export type IProductsResponse = IPaginatedGridResponse<
  Array<Partial<TProduct>>
>;

export type IProductListAttributesTypeResponse = TAttributeType;

export type IProductListAttributesResponse = IPaginatedGridResponse<
  Array<Partial<TAttribute>>
>;

export type IProductListPoolsResponse = IPaginatedGridResponse<
  Array<Partial<TPool>>
>;

export type IProductListCategoryTreeResponse = Array<
  Partial<TCategoryResponse>
>;

export type AttributeOption = {
  code: string;
  attribute: string;
  sortOrder: unknown;
  label: string;
};

export interface IProductAttributePm {
  attributeId: string;
  label?: string;
  type?: string;
  filterable?: boolean;
  options?: AttributeOption[];
}

export interface IProductPageConfigResponse {
  categoryIds: string[];
  skus: string[];
}

export const ATTRIBUTE_LIST_MOCK = {
  attributes: [
    {
      id: "Brand",
      code: "Brand",
      filterable: true,
      type: "pim_catalog_multiselect",
    },
    {
      id: "ERPCategory",
      code: "ERPCategory",
      filterable: true,
      type: "pim_catalog_boolean",
    },
    {
      id: "ERP",
      code: "ERP",
      filterable: true,
    },
    {
      id: "promotionPrice",
      code: "promotionPrice",
      filterable: true,
    },
    {
      id: "Pools",
      code: "Pools",
      filterable: true,
    },
    {
      id: "APRShortname",
      code: "APRShortname",
      filterable: true,
      searchable: false,
      type: "pim_catalog_number",
      label: "APR Shortname",
      group: "portal_attributes",
      plGroupLabel: null,
      usGroupLabel: "Portal Attributes",
      sortOrder: 0,
      localizable: true,
      scopable: false,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: null,
      numberMin: null,
      numberMax: null,
      decimalsAllowed: false,
      negativeAllowed: false,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: null,
    },
    {
      id: "B2BRelevant",
      code: "B2BRelevant",
      filterable: true,
      searchable: false,
      type: "pim_catalog_number",
      label: "B2B Relevant",
      group: "from_erp",
      plGroupLabel: null,
      usGroupLabel: "From ERP",
      sortOrder: 0,
      localizable: true,
      scopable: false,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: null,
      numberMin: null,
      numberMax: null,
      decimalsAllowed: false,
      negativeAllowed: false,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: null,
    },
    {
      id: "DLUCS",
      code: "DLUCS",
      filterable: true,
      searchable: true,
      type: "pim_catalog_number",
      label: "DLUCS",
      group: "portal_attributes",
      plGroupLabel: null,
      usGroupLabel: "Portal Attributes",
      sortOrder: 0,
      localizable: false,
      scopable: false,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: null,
      numberMin: "0.0000",
      numberMax: "100000.0000",
      decimalsAllowed: false,
      negativeAllowed: false,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: null,
    },
    {
      id: "EAN",
      code: "EAN",
      filterable: true,
      searchable: false,
      type: "pim_catalog_text",
      label: "EAN code",
      group: "from_erp",
      plGroupLabel: null,
      usGroupLabel: "From ERP",
      sortOrder: 0,
      localizable: true,
      scopable: false,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: true,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: null,
      numberMin: null,
      numberMax: null,
      decimalsAllowed: null,
      negativeAllowed: null,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: null,
    },
    {
      id: "FullName",
      code: "FullName",
      filterable: true,
      searchable: false,
      type: "pim_catalog_text",
      label: "full product name",
      group: "from_erp",
      plGroupLabel: null,
      usGroupLabel: "From ERP",
      sortOrder: 0,
      localizable: true,
      scopable: false,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: null,
      numberMin: null,
      numberMax: null,
      decimalsAllowed: null,
      negativeAllowed: null,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: null,
    },
    {
      id: "Image",
      code: "Image",
      filterable: true,
      searchable: false,
      type: "pim_catalog_image",
      label: "Image",
      group: "mandatory",
      plGroupLabel: "Required",
      usGroupLabel: "Required",
      sortOrder: 0,
      localizable: true,
      scopable: false,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: true,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: null,
      numberMin: null,
      numberMax: null,
      decimalsAllowed: null,
      negativeAllowed: null,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: null,
    },
    {
      id: "OpCo",
      code: "OpCo",
      filterable: true,
      searchable: false,
      type: "pim_catalog_simpleselect",
      label: "Sales channel",
      group: "from_erp",
      plGroupLabel: null,
      usGroupLabel: "From ERP",
      sortOrder: 0,
      localizable: true,
      scopable: false,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: true,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: null,
      numberMin: null,
      numberMax: null,
      decimalsAllowed: null,
      negativeAllowed: null,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: [
        {
          code: "OHU1",
          attribute: "OpCo",
          sortOrder: null,
          label: "OHU",
        },
        {
          code: "OPL2",
          attribute: "OpCo",
          sortOrder: null,
          label: "OPL",
        },
      ],
    },
    {
      id: "PortalAnchor",
      code: "PortalAnchor",
      filterable: true,
      searchable: false,
      type: "pim_catalog_boolean",
      label: "Portal Anchor",
      group: "portal_attributes",
      plGroupLabel: null,
      usGroupLabel: "Portal Attributes",
      sortOrder: 0,
      localizable: true,
      scopable: false,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: null,
      numberMin: null,
      numberMax: null,
      decimalsAllowed: null,
      negativeAllowed: null,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: null,
    },
    {
      id: "PortalBestseller",
      code: "PortalBestseller",
      filterable: true,
      searchable: false,
      type: "pim_catalog_boolean",
      label: "Portal Bestseller",
      group: "portal_attributes",
      plGroupLabel: null,
      usGroupLabel: "Portal Attributes",
      sortOrder: 0,
      localizable: true,
      scopable: false,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: true,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: null,
      numberMin: null,
      numberMax: null,
      decimalsAllowed: null,
      negativeAllowed: null,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: null,
    },
    {
      id: "PortalEndNew",
      code: "PortalEndNew",
      filterable: true,
      searchable: false,
      type: "pim_catalog_date",
      label: "Portal End New",
      group: "portal_attributes",
      plGroupLabel: null,
      usGroupLabel: "Portal Attributes",
      sortOrder: 0,
      localizable: true,
      scopable: false,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: null,
      numberMin: null,
      numberMax: null,
      decimalsAllowed: null,
      negativeAllowed: null,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: null,
    },
    {
      id: "BasePrice",
      code: "BasePrice",
      filterable: true,
      searchable: false,
      type: "pim_catalog_boolean",
      label: "BasePrice",
      group: "BasePrice",
      plGroupLabel: null,
      usGroupLabel: "BasePrice",
      sortOrder: 0,
      localizable: true,
      scopable: false,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: null,
      numberMin: null,
      numberMax: null,
      decimalsAllowed: null,
      negativeAllowed: null,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: null,
    },
    {
      id: "ProductDescirption",
      code: "ProductDescirption",
      filterable: true,
      searchable: false,
      type: "pim_catalog_textarea",
      label: "DESC",
      group: "portal_attributes",
      plGroupLabel: null,
      usGroupLabel: "Portal Attributes",
      sortOrder: 0,
      localizable: true,
      scopable: false,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: true,
      numberMin: null,
      numberMax: null,
      decimalsAllowed: null,
      negativeAllowed: null,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: null,
    },
    {
      id: "SZECS",
      code: "SZECS",
      filterable: true,
      searchable: true,
      type: "pim_catalog_number",
      label: "SZECS",
      group: "portal_attributes",
      plGroupLabel: null,
      usGroupLabel: "Portal Attributes",
      sortOrder: 0,
      localizable: false,
      scopable: false,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: null,
      numberMin: "0.0000",
      numberMax: "10000.0000",
      decimalsAllowed: false,
      negativeAllowed: false,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: null,
    },
    {
      id: "WEIGHT",
      code: "WEIGHT",
      filterable: true,
      searchable: true,
      type: "pim_catalog_number",
      label: "WEIGHT",
      group: "portal_attributes",
      plGroupLabel: null,
      usGroupLabel: "Portal Attributes",
      sortOrder: 0,
      localizable: false,
      scopable: false,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: null,
      numberMin: "0.0000",
      numberMax: "10000.0000",
      decimalsAllowed: false,
      negativeAllowed: false,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: null,
    },
    {
      id: "WYSCS",
      code: "WYSCS",
      filterable: true,
      searchable: true,
      type: "pim_catalog_number",
      label: "WYSCS",
      group: "portal_attributes",
      plGroupLabel: null,
      usGroupLabel: "Portal Attributes",
      sortOrder: 0,
      localizable: false,
      scopable: false,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: null,
      numberMin: "0.0000",
      numberMax: "1000.0000",
      decimalsAllowed: false,
      negativeAllowed: false,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: null,
    },
    {
      id: "File1",
      code: "File1",
      filterable: true,
      searchable: false,
      type: "pim_catalog_file",
      label: "File1",
      group: "portal_attributes",
      plGroupLabel: null,
      usGroupLabel: "Portal Attributes",
      sortOrder: 1,
      localizable: true,
      scopable: false,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: null,
      numberMin: null,
      numberMax: null,
      decimalsAllowed: null,
      negativeAllowed: null,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: null,
    },
    {
      id: "AssetCode",
      code: "AssetCode",
      filterable: true,
      searchable: false,
      type: "pim_catalog_text",
      label: "Asset Code",
      group: "portal_attributes",
      plGroupLabel: null,
      usGroupLabel: "Portal Attributes",
      sortOrder: 2,
      localizable: true,
      scopable: false,
      availableLocales: ["en_US", "pl_PL"],
      unique: false,
      useableAsGridFilter: true,
      maxCharacters: null,
      validationRule: null,
      validationRegexp: null,
      wysiwygEnabled: null,
      numberMin: null,
      numberMax: null,
      decimalsAllowed: null,
      negativeAllowed: null,
      metricFamily: null,
      defaultMetricUnit: null,
      dateMin: null,
      dateMax: null,
      allowedExtensions: [],
      maxFileSize: null,
      referenceDataName: null,
      defaultValue: null,
      options: null,
    },
  ],
};

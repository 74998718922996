import { isEmpty, mergeWith } from "lodash";
import merge from "lodash.merge";
import { nanoidNumber } from "../../helpers";
import {
  IBoxPageBuilder,
  ILayoutPageBuilder,
  IPublicationPageBuilder,
  ISectionPageBuilder,
  ISlotPageBuilder,
  IStackPageBuilder,
} from "./pageBuilder.types";
import {
  IPortalBox,
  IPortalPage,
  IPortalPublication,
  IPortalSection,
  IPortalSlot,
  IPortalStack,
  TPageContents,
} from "./portal.types";
import { IBoxData } from "./union.types";

export const PageBuilderLayoutToPage = (
  layout: ILayoutPageBuilder,
  boxes?: { [index in string | number]: IBoxPageBuilder },
  headerBoxes?: { [index in string | number]: IBoxPageBuilder },
  footerBoxes?: { [index in string | number]: IBoxPageBuilder },
  isMobile?: boolean
): IPortalPage => ({
  id: layout.id,
  name: layout.name,
  url: layout.url,
  type: layout.type,
  contents: PageBuilderContentsToPageContent(layout.content),
  sections: PageBuilderSectionToPageSection(layout.sections, boxes, isMobile),
  header: layout.header
    ? PageBuilderLayoutToPage(
        layout.header,
        headerBoxes,
        undefined,
        undefined,
        isMobile
      )
    : null,
  footer: layout.footer
    ? PageBuilderLayoutToPage(
        layout.footer,
        footerBoxes,
        undefined,
        undefined,
        isMobile
      )
    : null,
});

export const PageBuilderContentsToPageContent = (
  contents: {
    id: number;
    description: string;
    version: number;
    title: string;
    productCategoryDescription: null;
    keywords: string;
    language: string;
  }[]
): TPageContents => [
  {
    id: contents?.[0]?.id,
    title: contents?.[0]?.title,
    description: contents?.[0]?.description,
    keywords: contents?.[0]?.keywords,
    language: contents?.[0]?.language,
  },
];

export const PageBuilderSectionToPageSection = (
  sections: ISectionPageBuilder[],
  boxes?: { [index in string | number]: any },
  isMobile?: boolean
): IPortalSection[] => {
  return sections?.map((s) => {
    return {
      id: s.id,
      customCss: s.customCss,
      uuid: s.uuid,
      mobileSubSection: s.mobileSubSection,
      sectionOrder: s.sectionOrder ?? -1,
      slots: PageBuilderSlotToPageSlot(s.slots, boxes, isMobile),
      displaySettings: isMobile
        ? mergeWith(
            {},
            s?.displaySettings,
            s?.mobileDisplaySettings,
            (objValue, srcValue) => (srcValue === null ? objValue : srcValue)
          )
        : s?.displaySettings ?? {},
    };
  });
};

export const PageBuilderSlotToPageSlot = (
  slots: ISlotPageBuilder[],
  boxes?: { [index in string | number]: any },
  isMobile?: boolean
): IPortalSlot[] => {
  return slots.map((s) => {
    return {
      id: s.id,
      customCss: s.customCss,
      sectionId: s.sectionId ?? -1,
      columns: s.columns,
      groupingMode: s.groupingMode,
      slotOrder: s.slotOrder ?? -1,
      stacks: PageBuilderStackToPageStack(s.stacks, boxes, isMobile),
      sections: PageBuilderSectionToPageSection(s.sections, boxes, isMobile),
      displaySettings: isMobile
        ? mergeWith(
            {},
            s?.displaySettings,
            s?.mobileDisplaySettings,
            (objValue, srcValue) => (srcValue === null ? objValue : srcValue)
          )
        : s?.displaySettings ?? {},
    };
  });
};

export const PageBuilderStackToPageStack = (
  stacks: IStackPageBuilder[],
  boxes?: { [index in string | number]: any },
  isMobile?: boolean
): IPortalStack[] => {
  return stacks.map((s) => {
    return {
      publications: PageBuilderPublicationToPagePublication(
        s.publications,
        boxes,
        isMobile
      ),
    };
  });
};

// TODO: deprecated
export const PageBuilderPublicationToPagePublication = (
  publications: IPublicationPageBuilder[],
  boxes?: { [index in string | number]: any },
  isMobile?: boolean
): IPortalPublication[] => {
  return publications
    .filter((p) => p.published)
    .map((p) => {
      return {
        id: p?.id ?? -nanoidNumber(),
        box: PageBuilderBoxToPageBox(boxes?.[p?.boxId], isMobile),
        publicationOrder: p?.publicationOrder ?? -1,
        width: p?.width,
        alignment: p?.alignment,
        height: "", //nwm
      };
    });
};

export const PageBuilderBoxToPageBox = (
  box?: IBoxPageBuilder<IBoxData>,
  isMobile?: boolean
): IPortalBox<IBoxData> => {
  return {
    id: box?.id ?? -nanoidNumber(),
    name: box?.name ?? "",
    type: box?.type ?? "HTML",
    content: box?.content ?? {},
    messages: box?.messages ?? {},
    settings: isMobile
      ? merge({}, box?.settings, box?.mobileSettings)
      : box?.settings ?? {},
    displaySettings: isMobile
      ? isEmpty(box?.mobileDisplaySettings ?? {})
        ? box?.displaySettings ?? {}
        : box?.mobileDisplaySettings ?? {}
      : box?.displaySettings ?? {},
    contentSettings: isMobile
      ? isEmpty(box?.mobileContentSettings ?? {})
        ? box?.contentSettings ?? {}
        : box?.mobileContentSettings ?? {}
      : box?.contentSettings ?? {},
    local: box?.local ?? false,
    version: box?.version ?? -1,
    customCss: box?.customCss ?? "",
    customJs: box?.customJs ?? "",
  };
};

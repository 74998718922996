import { TUrl } from "./themeSettings/themeSettings.types";

export enum FacetParam {
  TREE = "TREE",
  FACET = "FACET",
}

interface FacetValueDto {
  name: string;
  count: number;
}

export interface CategoryValueDto {
  name: string;
  count: number;
  id: string;
  parentId: string;
}

export interface FacetCatDto<T extends FacetParam> {
  name: string;
  type: T;
  values: T extends FacetParam.FACET ? FacetValueDto[] : CategoryValueDto[];
}

export const isCategoryValue = (
  param: FacetCatDto<FacetParam>
): param is FacetCatDto<FacetParam.TREE> => param.type === FacetParam.TREE;

export interface CategoryDto {
  name: string; // "cat02" "cat03" .. "0202"
  type: FacetParam.TREE;
  values: {
    name: string;
    count: number;
    id: string;
    parentId: string; // "cat02" "cat03" .. "0202"
  };
}

export type TAttributeKey = string;
export type TAttributeValue = {
  value: string;
  label: string | null;
};

interface DebugFields {
  name: string;
  index: string;
  score: string;
}

export interface ISrpRequest {
  skus: string[];
  basicInfo: string[];
  attributes: string[];
  associations?: string[];
}

export interface ISrpWithSearchTransformResponse {
  products: ISrpTransformResponse[];
  size: number;
  attributesLineDetection: CustomAttributesLineDetection;
}

export interface ISrpWithSearchTransformResponseV2 {
  products: ISrpTransformResponseV2[];
  size: number;
  attributesLineDetection: CustomAttributesLineDetection;
}

export interface ISrpWithSearchResponse {
  products: ISrpResponse[];
  size: number;
}

export interface ISrpWithSearchRequest extends Omit<ISrpRequest, "skus"> {
  query: string;
}

export interface ISearchResultsRequest {
  query: string;
  strictCategory?: string; // is inside params
}

export interface ISearchResultsResponse {
  size: number;
  products: {
    sku: string;
    debugFields?: DebugFields; // &mode=debug
  }[];
  parameters: FacetCatDto<FacetParam>[];
}

export type TSkusResponse = Omit<ISearchResultsResponse, "parameters">;

export interface ISearchResultsTransformResponse {
  size: number;
  products: string[];
  parameters: FacetCatDto<FacetParam>[];
}

export interface SrpInfoDto {
  name: string | null;
  price: {
    basePrice: number;
    promotionPrice: number | null;
    netPrice: number | null;
    grossPrice: number | null;
  };
  tax: number | null;
  coverPhoto: TUrl | null;
  wishlist: { base: boolean; concept: boolean };
  hoverPhoto: TUrl | null;
  deliveryTime: DeliveryTimeCode | null;
  badges: BadgeDto[];
  rating: {
    numberOfReviews: number;
    rate: number | null;
  };
  pools?: Pool[];
  url: TUrl;
  photoGallery?: TUrl[];
  variants: VariantDto[];
  axis: string;
  lowestCategoryLevel?: string;
  deliveryQuantity: number;
  alternativeUnitOfMeasures: {
    auoM: string;
    auoMLabel: string;
    value: number;
  }[];
  mainUnitOfMeasurement: string;
  mainUnitOfMeasurementLabel: string;
  concession: boolean;
}

interface Pool {
  id: number;
  name: string;
}

export enum TSrpProductType {
  PRODUCT = "PRODUCT",
  VARIANT = "VARIANT",
  CONCEPT = "CONCEPT",
}

export interface ISrpResponse {
  sku: string;
  type: TSrpProductType;
  srpInfo: SrpInfoDto;
  attributes?: Record<TAttributeKey, TAttributeValue | TAttributeValue[]>;
  associations: Record<string, string[]>;
  conceptId: string | null;
}

export interface ISrpTransformResponse
  extends Omit<ISrpResponse, "srpInfo">,
    Omit<SrpInfoDto, "price" | "deliveryTime"> {
  price: {
    actualPrice: number;
    oldPrice: number | null;
    netPrice: number | null;
    grossPrice: number | null;
  };
  deliveryTime: DeliveryTimeCode;
  associations: Record<string, string[]>;
  conceptId: string | null;
}

export type ISrpTransformResponseV2 = {
  rating: {
    numberOfReviews: number;
    rate: number | null;
  };
  badges: BadgeDto[];
  pools: Pool[];
  deliveryTime: DeliveryTimeCode;
  sku: string;
  conceptId: string;
  url: string;
  type: TSrpProductType;
  mainUnitOfMeasurement: string;
  mainUnitOfMeasurementLabel: string;
  alternativeUnitOfMeasures: {
    value: number;
    auoM: string;
    auoMLabel: string;
  }[];
  deliveryQuantity: number;
  concession: boolean;
} & {
  [key: string]:
    | string
    | number
    | boolean
    | null
    | TAttributeValue
    | TAttributeValue[];
};

export type VariantDto = {
  sku: string;
  attribute: {
    value: string;
    label: string;
  };
  order: number;
  inStock: boolean;
  deliveryTime: DeliveryTimeCode | null;
  enabled: boolean;
  productUrl: string;
};

interface BadgeDto {
  id: number;
  name: string;
  badgeKind: BadgeType;
}

export enum BadgeType {
  PROMOTION = "PROMOTION",
  BESTSELLER = "BESTSELLER",
  NEW = "NEW",
}

export enum DeliveryTimeCode {
  HOURS_24 = "HOURS_24",
  HOURS_48 = "HOURS_48",
  HOURS_72 = "HOURS_72",
  DAYS_4_5 = "DAYS_4_5",
  DAYS_6_8 = "DAYS_6_8",
  WEEKS_2 = "WEEKS_2",
  NOT_AVAILABLE = "NOT_AVAILABLE",
}

export enum SortingItemType {
  DATE_ADDED = "DATE_ADDED",
  DATE_ADDED_DESC = "DATE_ADDED_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
  RATING_ASC = "RATING_ASC",
  RATING_DESC = "RATING_DESC",
  POPULARITY = "POPULARITY",
  RANDOM = "RANDOM",
  BY_ATTRIBUTE = "BY_ATTRIBUTE",
}

export enum SortingItemQuery {
  DATE_ADDED = "created_at",
  DATE_ADDED_DESC = "-created_at",
  NAME_ASC = "name",
  NAME_DESC = "-name",
  PRICE_ASC = "price",
  PRICE_DESC = "-price",
  RATING_ASC = "rating",
  RATING_DESC = "-rating",
  POPULARITY = "score",
  RANDOM = "random",
  //Sorting by attribute is not specified because it is variable depending on what we get from the backend for a given attribute
  //The operation of sorting by attribute is done in a function called getSortQueryBySortType()
  BY_ATTRIBUTE = "",
}

export enum SortingItemVariant {
  ASC = "ASC",
  DESC = "DESC",
}

export type CustomAttributesLineDetection = {
  productName: boolean;
  oldPrice: boolean;
  netPrice: boolean;
  actualPrice: boolean;
  rating: boolean;
  tax: boolean;
  badges: boolean;
  pools: boolean;
  grossPrice: boolean;
  custom: Record<TAttributeKey, boolean>;
};

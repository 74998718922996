import router from "next/router";
import * as pages from "@ecp-redux/api/pages";
import * as theme from "@ecp-redux/api/themeSettings";
import { TSnackbarId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  AnyAction,
  Dispatch,
  Middleware,
  MiddlewareAPI,
  ThunkDispatch,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { isClientSide } from "../../helpers/helpers";
import { convertSnackbarIdToValues } from "../../settingsPatterns/settingsPatterns.methods";
import { renderPortalSnackbar } from "../../shared/components/Snackbar/Snackbar";
import { RootStatePortal } from "../store";

interface IErrorMessage {
  code: number;
  message: string;
  error: string;
}

interface IError {
  status: number;
  originalStatus: number;
  data: IErrorMessage;
}

export interface IErrorAction {
  type: string;
  payload: IError;
  meta: any;
}

const disable401RedirectionEndpoints = [
  "postAddProductToWishList",
  "deleteProductFromWishList",
];

const redirectOnLoginForEndpointsError = ["getContractors"]; //getContractors every endpoint should be redirected to login

const disableErrorSnackbarEndpoints = [
  ...disable401RedirectionEndpoints,
  "postAddProductQuantity",
  "postAddProductQuantityWithMax",
  "putChangeProductCount",
  "getDeliveryAndPaymentByPortalUserToken",
  "postCartPlaceOrder",
  "postCartShoppingWithoutRegister",
  "postChangePassword",
  "postRegister",
  "postMyOrdersReorderProduct",
  "postMyOrdersMergeProduct",
  "postContactForm",
  "postLogin",
  "putMyDataForm",
];

const getThemeSettings = (store: MiddlewareAPI<Dispatch<AnyAction>, any>) =>
  theme.getThemeSettingsPortal.select("DESKTOP")(store.getState()).data ??
  theme.getThemeSettingsPortal.select("MOBILE")(store.getState()).data;

export const portalApiSnackbarErrorHandler: Middleware =
  (store) => (next) => (action: IErrorAction) => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const endpointName = action?.meta?.arg?.endpointName as string;

    if (!isRejectedWithValue(action)) return next(action);

    const themeSettings = getThemeSettings(store);

    if (themeSettings === undefined) {
      console.error(
        "Error handling didn't show up because theme settings were not loaded"
      );
      return next(action);
    }

    const { payload } = action || {};

    if (
      !disableErrorSnackbarEndpoints.includes(endpointName) &&
      themeSettings !== undefined &&
      payload.status !== 401
    ) {
      if (payload.data !== undefined) {
        const { status, data } = payload;

        const errorsMessages: string = data?.message;

        renderPortalSnackbar(
          convertSnackbarIdToValues(
            themeSettings?.advanceSettings.settings[
              "snackbarApiError"
            ] as TSnackbarId,
            themeSettings?.globalObjects.snackbars ?? []
          ),
          `${status}: ${errorsMessages}`,
          themeSettings
        );
      }
    }

    return next(action);
  };

export const portalApiError500Handler: Middleware<
  unknown,
  RootStatePortal,
  ThunkDispatch<RootStatePortal, unknown, AnyAction>
> = (store) => (next) => async (action: IErrorAction) => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!isRejectedWithValue(action)) return next(action);

  const themeSettings = getThemeSettings(store);

  if (themeSettings === undefined) {
    console.error(
      "Error handling didn't show up because theme settings were not loaded"
    );
    return next(action);
  }

  const { payload } = action || {};

  if (payload.status === 500 && isClientSide()) {
    if (themeSettings.systemPages.page500.id !== -1) {
      const url500 = await store.dispatch(
        pages.getPageUrlById.initiate(themeSettings.systemPages.page500.id)
      );
      if (!url500.data?.draft && url500.data?.url) {
        router.push(url500.data.url);
      } else {
        router.push("/_error");
      }
    } else {
      router.push("/_error");
    }
  }
  return next(action);
};

export const portalApiErrorUnauthorizedUserHandler: Middleware<
  unknown,
  RootStatePortal,
  ThunkDispatch<RootStatePortal, unknown, AnyAction>
> = (store) => (next) => async (action: IErrorAction) => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions

  if (!isRejectedWithValue(action)) return next(action);

  const themeSettings = getThemeSettings(store);

  const endpointName = action?.meta?.arg?.endpointName as string;

  const { payload } = action || {};

  if (payload.status === 403 && isClientSide()) {
    router.replace("/log_out_tech");
  }

  if (themeSettings === undefined) {
    console.error(
      "Error handling didn't show up because theme settings were not loaded"
    );
    return next(action);
  }

  if (
    isClientSide() &&
    !disable401RedirectionEndpoints.includes(endpointName) &&
    (payload.status === 401 ||
      redirectOnLoginForEndpointsError.includes(endpointName))
  ) {
    router.replace(themeSettings.advanceSettings.settings.loginUrl);
  }

  return next(action);
};

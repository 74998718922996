import styled, { css } from "styled-components";
import {
  IBoxBaseSettings,
  IElementTextContent,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import ConditionalHTMLElement from "../../../global/components/ConditionalHTMLElement/ConditionalHTMLElement";
import composeCss from "../../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";

const StyledText = styled(ConditionalHTMLElement).attrs((props) => ({
  renderAs: props?.["renderAs"] ?? "div",
  show:
    props?.["$settings"]?.show === false || props["show"] === false
      ? false
      : true,
}))<
  IBoxBaseSettings<
    IElementTextContent & {
      textDecoration?: React.CSSProperties["textDecoration"];
    }
  >
>(({ $settings, alignment, theme, backgroundColor }) => {
  const bg = convertColorIdToHex(backgroundColor, theme.colorPalette);
  return css`
    color: ${convertColorIdToHex($settings.text.color, theme.colorPalette)};
    ${composeCss.font($settings.font, theme.typography)};
    ${composeCss.textDecoration($settings.textDecoration)};
    ${alignment ? `text-align: ${alignment};` : ""};
    ${backgroundColor ? `background-color: ${bg};` : ""};
  `;
});

export default StyledText;

import { FieldValues } from "react-hook-form";
import { z } from "zod";
import {
  ILayoutPageBuilder,
  PageCategoryType,
  SectionType,
} from "@ecp-pageTypes";
import { TUrl } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { ILinkContent } from "../settingsPatterns/contentPatterns.types";
import { ICheckboxProps } from "../shared/components/CheckboxesList/CheckboxesList";

// for checking changes in complex data structures
export const asJSON = (val: unknown) => {
  return JSON.stringify(val);
};

export const noEmptyValue = (value: string | null | undefined): boolean =>
  value !== "" && value !== null && value !== undefined;

export const isTruthy = (setting: string): boolean => setting === "true";

export const capitalizeFirstLetter = (word: string): string =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const convertUrlToImage = async (imageUrl: string) => {
  if (imageUrl === "") return;

  const img = new Image();
  img.src = imageUrl;

  await img.decode?.();
  return img;
};

export const sortPages = (data: ILayoutPageBuilder[] | undefined) => {
  return data
    ?.map((element: ILayoutPageBuilder) => element.name)
    .sort((a, b) => sortIgnoringCapitalization(a, b));
};

export const sortIgnoringCapitalization = (a: string, b: string) => {
  return a.toLowerCase().localeCompare(b.toLowerCase());
};

export const isErrorByCode = (response: any, codes: number[]): boolean => {
  if (response?.error?.data?.code) {
    return codes.includes(response.error.data.code);
  }
  if (response?.data?.code) {
    return codes.includes(response.data.code);
  }
  return false;
};

export const checkSlidesPerView = (
  numberFromSettings: number,
  isMobile: boolean
): number => {
  if (numberFromSettings > 2 && isMobile) {
    return 2;
  }
  if (numberFromSettings < 2 && isMobile) {
    return numberFromSettings;
  }
  return numberFromSettings;
};
export const replaceBetween = (
  phrase: string,
  start: string,
  end: string,
  newFragment: string
): string => {
  const regex = new RegExp(`${start}(.*?)${end}`, "g");
  return phrase.replace(regex, `${start}${newFragment}${end}`);
};

export const isClientSide = (): boolean => typeof window !== "undefined";
export const isPortalSide = (): boolean =>
  process.env["NEXT_PUBLIC_APP_NAME"] === "portal";

export const getImageUrlOrFallback = (
  imageUrl: TUrl,
  fallbackUrl: TUrl
): TUrl => {
  return imageUrl?.length > 0 ? imageUrl : fallbackUrl;
};

export const formatPrice = (
  price: number,
  currencyShort?: string,
  showDecimals = true
) => {
  if (price === undefined || price === null) return "";

  const fractionDigits = !showDecimals && Number.isInteger(price) ? 0 : 2;

  return `${price.toLocaleString("pl-PL", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })} ${currencyShort ?? ""}`;
};

export const formatPercentage = (rate: number, unit?: string) => {
  if (rate === undefined || rate === null) return "";
  const fixed = rate % 1 === 0 ? 0 : 2;
  return `${rate.toFixed(fixed)}${unit ?? ""}`.replace(`.`, `,`);
};

export function getDefaultsFromZod<TSchema extends z.AnyZodObject>(
  schema: TSchema
): typeof schema["_type"] {
  function getDefaultValue(schema: z.ZodTypeAny): unknown {
    if (schema instanceof z.ZodDefault) return schema._def.defaultValue();
    if (!("innerType" in schema._def)) return undefined;
    return getDefaultValue(schema._def.innerType);
  }

  return Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => {
      return [key, getDefaultValue(value as z.ZodTypeAny)];
    })
  );
}

export const checkIfMoreThanOneOtherCheckbox = (
  checkboxes: Omit<ICheckboxProps<FieldValues>, "register" | "textStyles">[]
): boolean => {
  return checkboxes.filter((checkbox) => checkbox.label).length > 1;
};

export const transformPageTypeToSectionType = (
  pageType: PageCategoryType
): SectionType => {
  if (pageType === PageCategoryType.HEADER) {
    return SectionType.HEADER;
  }
  if (pageType === PageCategoryType.FOOTER) {
    return SectionType.FOOTER;
  }
  return SectionType.MAIN;
};

export const checkIfLinkExists = ({
  goToPageSetting,
  goToRowSetting,
}: ILinkContent): boolean => {
  const link =
    goToPageSetting &&
    goToPageSetting.link !== null &&
    goToPageSetting.link.length > 0;
  const rowId =
    goToRowSetting &&
    goToRowSetting.rowId !== null &&
    goToRowSetting.rowId.toLocaleString().length > 0;
  return (link || rowId) ?? false;
};

export const getImageNameFromUrl = (imageUrl: string): string => {
  const splited = imageUrl.split("/");
  return splited[splited.length - 1];
};

export const getJustifyContentValue = (spaceBetween: boolean) => {
  return spaceBetween ? "space-between" : "flex-end";
};

import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import {
  IBoxBaseSettings,
  IElementTextContent,
  IElementVideo,
  IVideoSize,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  ISpacing,
  TUrl,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";

export enum RatioOption {
  ONE_TO_ONE = "ONE_TO_ONE",
  FIVE_TO_FOUR = "FIVE_TO_FOUR",
  FOUR_TO_THREE = "FOUR_TO_THREE",
  THREE_TO_TWO = "THREE_TO_TWO",
  FIVE_TO_THREE = "FIVE_TO_THREE",
  SIXTEEN_TO_NINE = "SIXTEEN_TO_NINE",
  THREE_TO_ONE = "THREE_TO_ONE",
}

export type IBoxVideoData = GenericBoxData<
  IBoxVideoContent,
  IBoxVideoContentSettings,
  IBoxVideoDisplaySettings,
  Record<string, never>,
  Record<string, never>
>;

export interface IBoxVideoContent {
  videoTitle: string;
  coverImage: TUrl;
  videoURL: TUrl;
  seoDescription: string;
}

export interface IBoxVideoDisplaySettings {
  padding: Pick<ISpacing, "top" | "bottom">;
  ratio: RatioOption;
  size: IVideoSize;
}

export interface IBoxVideoContentSettings {
  title: IElementTextContent;
  video: IElementVideo;
}

export type IBoxVideoSettings = IBoxBaseSettings<
  IBoxVideoDisplaySettings & IBoxVideoContentSettings
>;

import React from "react";
import { TConditionalElementProps } from "./ConditionalHTMLElement.types";

const ConditionalHTMLElement = React.forwardRef(
  <C extends React.ElementType>(
    {
      show = true,
      renderAs,
      children,
      isOpen,
      ...props
    }: TConditionalElementProps<C>,
    ref?: React.Ref<HTMLDivElement>
  ) => {
    const HTMLElement = renderAs ?? "div";

    return show ? (
      <HTMLElement {...props} ref={ref}>
        {children}
      </HTMLElement>
    ) : null;
  }
);

export default ConditionalHTMLElement;

import {
  IProductListAttributesResponse,
  IProductListAttributesTypeResponse,
  IProductListCategoryTreeResponse,
  IProductListPoolsResponse,
  IProductsResponse,
  PIMAttributeTypes,
  PIMInputTypes,
} from "../../dto/productList.types";

export const PRODUCTS_MOCK: IProductsResponse = {
  result: [
    {
      badges: { 3: "BADGE 1" },
      basePrice: 81.06,
      createdBy: undefined,
      creationDate: undefined,
      deliveryTime: undefined,
      enabled: true,
      family: "test",
      id: "1",
      image:
        "https://dev-minio-ecp.nd0.pl/poc1-pim-dev/0/7/6/9/0769c5ad8a6a5a9e554b0f29560e3d9d130e3811_PL_654321_1.jpg",

      modificationDate: "2022-10-04T13:21:23.933135Z",
      modifiedBy: "System",
      photoGallery: [
        { order: 1, url: "/static/images/placeholder.png" },
        { order: 2, url: "/static/images/placeholder.png" },
        { order: 3, url: "/static/images/placeholder.png" },
        { order: 4, url: "/static/images/placeholder.png" },
        { order: 5, url: "/static/images/placeholder.png" },
        { order: 6, url: "/static/images/placeholder.png" },
      ],
      pools: { 15: "test" },
      productName: "test",
      sku: "1",
      stock: 1,
      visibility: true,
    },
    {
      badges: { 1: "BADGE 2" },
      basePrice: 81.06,
      createdBy: undefined,
      creationDate: undefined,
      deliveryTime: undefined,
      enabled: true,
      family: "test",
      id: "2",
      image: "/asd",
      modificationDate: "2022-10-04T13:21:23.933135Z",
      modifiedBy: "System",
      photoGallery: [{ order: 2, url: "/static/images/placeholder.png" }],
      pools: { 15: "test2" },
      productName: "test2",
      sku: "2",
      stock: 1,
      visibility: true,
    },
    {
      badges: { 1: "BADGE 1" },
      basePrice: 81.06,
      createdBy: undefined,
      creationDate: undefined,
      deliveryTime: undefined,
      enabled: true,
      family: "test",
      id: "3",
      image: undefined,
      modificationDate: "2022-10-04T13:21:23.933135Z",
      modifiedBy: "System",
      photoGallery: [{ order: 3, url: "/static/images/placeholder.png" }],
      pools: { 15: "test3" },
      productName: "test3",
      sku: "3",
      stock: 1,
      visibility: true,
    },
  ],
  page: {
    number: 1,
    size: 25,
    count: 1,
    total: 1,
  },
};

export const PRODUCT_LIST_PRODUCT_PROJECTION: string[] = [
  "0201multiselect",
  "0202multiselect",
  "0202singleselect",
  "5271multiselect",
  "5271number",
  "5271simpleselect",
  "AlcoholConcession",
  "alternativeUnitOfMeasures",
  "associations",
  "axis",
  "badges",
  "basePrice",
  "Brand",
  "breadcrumbs",
  "Bridge width",
  "conceptId",
  "concession",
  "concessions",
  "coverPhoto",
  "currency",
  "Default Price",
  "deliveryQuantity",
  "deliveryTime",
  "Description",
  "description",
  "DlaKogo",
  "Gallery picture 1",
  "Gallery picture 10",
  "Gallery picture 2",
  "Gallery picture 3",
  "Gallery picture 4",
  "Gallery picture 5",
  "Gallery picture 6",
  "Gallery picture 7",
  "Gallery picture 8",
  "Gallery picture 9",
  "Gender",
  "Gross price",
  "grossPrice",
  "gtBool",
  "Height",
  "hoverPhoto",
  "IIIIIIII",
  "Karton",
  "kolor",
  "Kolor pomadki",
  "KolorO",
  "KolorU",
  "Ksztalt",
  "Lalala",
  "Length",
  "Lense type",
  "lowestCategoryLevel",
  "Main picture",
  "mainUnitOfMeasurement",
  "mainUnitOfMeasurementLabel",
  "Material",
  "MaterialID",
  "medicalConcession",
  "Mokate",
  "Multipack",
  "Multiselect",
  "Name",
  "Net price",
  "netPrice",
  "ops",
  "Pablo",
  "Palette",
  "photoGallery",
  "Polarization",
  "pools",
  "Portal Display Order",
  "Poziom ekskluzywnosci",
  "Product category",
  "Product Name",
  "ProductModelImage",
  "ProductModelName",
  "Promotion price",
  "promotionPrice",
  "rating",
  "roundIndicator",
  "Sakura",
  "Shield width",
  "simpletest",
  "Size",
  "sku",
  "Stock",
  "Suggested Price",
  "TAX",
  "TaxClassification",
  "Temples width",
  "test0801I",
  "test0801II",
  "test102001",
  "test1112",
  "test1505",
  "Test1611",
  "Test2024II",
  "Test2024Itest",
  "test241001",
  "test241002",
  "test241003",
  "test241004",
  "test241005",
  "Test2601I",
  "Test2601II",
  "test2712en_en",
  "test2812en",
  "test2812II",
  "test2901multiselect",
  "test2901number",
  "test2901simpleselect",
  "test291101",
  "test291102",
  "test_121901",
  "test_2212",
  "test_281101",
  "testauom2",
  "testecp22",
  "testowyII",
  "TobaccoConcession",
  "Typ Produktu",
  "Type",
  "url",
  "Uuuuu",
  "variants",
  "Weight",
  "wersja",
  "Width",
  "wishlist",
  "Zosia test atrybut",
  "Zosia TEST ATRYBUT NUMER 3",
  "Zosia test numer 2",
  "Zosia Zosia",
  "zosia-testytesty",
  "Zzzzzzzz",
];

export const PRODUCT_LIST_POOLS_MOCK: IProductListPoolsResponse = {
  result: [
    {
      id: 1,
      version: 2,
      name: "Pool Test",
      badgeKind: undefined,
      modifiedBy: "ANONYMOUS",
      modificationDate: new Date("2022-07-14T08:43:40.267138Z"),
      createdBy: "ANONYMOUS",
      creationDate: new Date("2022-07-07T09:22:01.97469Z"),
      productCount: 1,
    },
  ],
  page: {
    number: 1,
    size: 25,
    count: 1,
    total: 1,
  },
};

export const PRODUCT_LIST_ATTRIBUTES_TYPE_MOCK: IProductListAttributesTypeResponse =
  {
    pim_catalog_date: {
      fieldType: "TIMESTAMP",
      path: "data",
      control: "DATE" as PIMAttributeTypes,
    },
    pim_catalog_file: {
      fieldType: "STRING",
      path: "data",
      control: "TEXT" as PIMAttributeTypes,
    },
    pim_catalog_image: {
      fieldType: "STRING",
      path: "data",
      control: "TEXT" as PIMAttributeTypes,
    },
    pim_catalog_textarea: {
      fieldType: "STRING",
      path: "data",
      control: "TEXT" as PIMAttributeTypes,
    },
    pim_catalog_price_collection: {
      fieldType: "DOUBLE",
      path: "data.amount",
      control: "NUMBER" as PIMAttributeTypes,
    },
    pim_catalog_boolean: {
      fieldType: "BOOLEAN",
      path: "data",
      control: "BOOLEAN" as PIMAttributeTypes,
    },
    pim_catalog_text: {
      fieldType: "STRING",
      path: "data",
      control: "TEXT" as PIMAttributeTypes,
    },
    pim_catalog_number: {
      fieldType: "DOUBLE",
      path: "data",
      control: "NUMBER" as PIMAttributeTypes,
    },
    pim_catalog_multiselect: {
      fieldType: "STRING",
      path: "data",
      control: "MULTISELECT" as PIMAttributeTypes,
    },
    pim_catalog_simpleselect: {
      fieldType: "STRING",
      path: "data",
      control: "SELECT" as PIMAttributeTypes,
    },
    pim_catalog_identifier: {
      fieldType: "STRING",
      path: "data",
      control: "TEXT" as PIMAttributeTypes,
    },
    pim_catalog_metric: {
      fieldType: "DOUBLE",
      path: "data.amount",
      control: "NUMBER" as PIMAttributeTypes,
    },
  };

export const PRODUCT_LIST_ATTRIBUTES_MOCK: IProductListAttributesResponse = {
  result: [
    {
      id: "Boolean Attribute",
      code: "Test",
      filterable: true,
      type: PIMInputTypes.pim_catalog_boolean,
      label: "Boolean Attribute",
      group: "from_erp",
      plGroupLabel: undefined,
      usGroupLabel: "From ERP",
      sortOrder: 0,
      localizable: false,
      scopable: true,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: undefined,
      validationRule: undefined,
      validationRegexp: undefined,
      wysiwygEnabled: undefined,
      numberMin: undefined,
      numberMax: undefined,
      decimalsAllowed: undefined,
      negativeAllowed: undefined,
      metricFamily: undefined,
      defaultMetricUnit: undefined,
      dateMin: undefined,
      dateMax: undefined,
      allowedExtensions: [],
      maxFileSize: undefined,
      referenceDataName: undefined,
      defaultValue: undefined,
      options: undefined,
      name: "Test",
    },
    {
      id: "Test2",
      code: "Test2",
      filterable: true,
      type: PIMInputTypes.pim_catalog_text,
      label: "Text Attribute",
      group: "from_erp",
      plGroupLabel: undefined,
      usGroupLabel: "From ERP",
      sortOrder: 0,
      localizable: false,
      scopable: true,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: undefined,
      validationRule: undefined,
      validationRegexp: undefined,
      wysiwygEnabled: undefined,
      numberMin: undefined,
      numberMax: undefined,
      decimalsAllowed: undefined,
      negativeAllowed: undefined,
      metricFamily: undefined,
      defaultMetricUnit: undefined,
      dateMin: undefined,
      dateMax: undefined,
      allowedExtensions: [],
      maxFileSize: undefined,
      referenceDataName: undefined,
      defaultValue: undefined,
      options: undefined,
      name: "Test2",
    },
    {
      id: "Test3",
      code: "Test3",
      filterable: true,
      type: PIMInputTypes.pim_catalog_number,
      label: "Number Attribute",
      group: "from_erp",
      plGroupLabel: undefined,
      usGroupLabel: "From ERP",
      sortOrder: 0,
      localizable: false,
      scopable: true,
      sortable: true,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: undefined,
      validationRule: undefined,
      validationRegexp: undefined,
      wysiwygEnabled: undefined,
      numberMin: undefined,
      numberMax: undefined,
      decimalsAllowed: undefined,
      negativeAllowed: undefined,
      metricFamily: undefined,
      defaultMetricUnit: undefined,
      dateMin: undefined,
      dateMax: undefined,
      allowedExtensions: [],
      maxFileSize: undefined,
      referenceDataName: undefined,
      defaultValue: undefined,
      options: undefined,
      name: "Test3",
    },
    {
      id: "Test4",
      code: "Test4",
      filterable: true,
      type: PIMInputTypes.pim_catalog_simpleselect,
      label: "Simpleselect Attribute",
      group: "from_erp",
      plGroupLabel: undefined,
      usGroupLabel: "From ERP",
      sortOrder: 0,
      localizable: false,
      scopable: true,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: undefined,
      validationRule: undefined,
      validationRegexp: undefined,
      wysiwygEnabled: undefined,
      numberMin: undefined,
      numberMax: undefined,
      decimalsAllowed: undefined,
      negativeAllowed: undefined,
      metricFamily: undefined,
      defaultMetricUnit: undefined,
      dateMin: undefined,
      dateMax: undefined,
      allowedExtensions: [],
      maxFileSize: undefined,
      referenceDataName: undefined,
      defaultValue: undefined,
      options: [
        {
          code: "OHU1",
          attribute: "Test4",
          sortOrder: null,
          label: "OHU",
        },
        {
          code: "OPL2",
          attribute: "Test4",
          sortOrder: null,
          label: "OPL",
        },
      ],
      name: "Test4",
    },
    {
      id: "Test5",
      code: "Test5",
      filterable: true,
      type: PIMInputTypes.pim_catalog_multiselect,
      label: "Multiselect Attribute",
      group: "from_erp",
      plGroupLabel: undefined,
      usGroupLabel: "From ERP",
      sortOrder: 0,
      localizable: false,
      scopable: true,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: undefined,
      validationRule: undefined,
      validationRegexp: undefined,
      wysiwygEnabled: undefined,
      numberMin: undefined,
      numberMax: undefined,
      decimalsAllowed: undefined,
      negativeAllowed: undefined,
      metricFamily: undefined,
      defaultMetricUnit: undefined,
      dateMin: undefined,
      dateMax: undefined,
      allowedExtensions: [],
      maxFileSize: undefined,
      referenceDataName: undefined,
      defaultValue: undefined,
      options: [
        {
          code: "OHU1",
          attribute: "Test5",
          sortOrder: null,
          label: "OHU",
        },
        {
          code: "OPL2",
          attribute: "Test5",
          sortOrder: null,
          label: "OPL",
        },
      ],
      name: "Test5",
    },
    {
      id: "Test6",
      code: "Test6",
      filterable: true,
      type: PIMInputTypes.pim_catalog_date,
      label: "Test6",
      group: "from_erp",
      plGroupLabel: undefined,
      usGroupLabel: "From ERP",
      sortOrder: 0,
      localizable: false,
      scopable: true,
      availableLocales: [],
      unique: false,
      useableAsGridFilter: false,
      maxCharacters: undefined,
      validationRule: undefined,
      validationRegexp: undefined,
      wysiwygEnabled: undefined,
      numberMin: undefined,
      numberMax: undefined,
      decimalsAllowed: undefined,
      negativeAllowed: undefined,
      metricFamily: undefined,
      defaultMetricUnit: undefined,
      dateMin: undefined,
      dateMax: undefined,
      allowedExtensions: [],
      maxFileSize: undefined,
      referenceDataName: undefined,
      defaultValue: undefined,
      options: undefined,
      name: "Test6",
    },
  ],
  page: {
    number: 1,
    size: 25,
    count: 6,
    total: 6,
  },
};

export const PRODUCT_LIST_CATEGORY_TREE_MOCK: {
  children: IProductListCategoryTreeResponse;
} = {
  children: [
    {
      label: "TEST",
      code: "01",
      updated: "2022-05-20T13:54:34+00:00",
      parentCode: "",
      children: [
        {
          label: "ChildrenTestCategory",
          code: "0101",
          updated: "2022-05-20T13:54:34+00:00",
          parentCode: "",
          children: [],
        },
      ],
    },
  ],
};

import {
  BadgeType,
  DeliveryTimeCode,
  ISrpResponse,
  TSrpProductType,
} from "../../dto/searchResults.types";

export const SEARCH_PRODUCTS_V2 = [
  {
    sku: "1001000171",
    associations: { TestLabel: ["1001000145"] },
    type: TSrpProductType.PRODUCT,
    conceptId: null,

    badges: [
      {
        id: 4,
        name: "BestsellerBadgeTest",
        badgeKind: BadgeType.BESTSELLER,
      },
      {
        id: 6,
        name: "PromotionBadgeTest",
        badgeKind: BadgeType.PROMOTION,
      },
      {
        id: 5,
        name: "New",
        badgeKind: BadgeType.NEW,
      },
    ],
    coverPhoto:
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/1/1/9/a/119ad993d31d92c1fbe64f97215de64e9791bc43_PL_8001090207418_1.jpg",
    hoverPhoto:
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
    deliveryTime: DeliveryTimeCode.DAYS_4_5,
    name: "LENOR Sensitive, płyn do płukania, 1360 ml",
    price: {
      basePrice: 16.3,
      promotionPrice: null,
      netPrice: 10,
      grossPrice: 12,
    },
    tax: 50,
    rating: {
      numberOfReviews: 322,
      rate: 1.4,
    },
    url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
    pools: [{ id: 1, name: "PulaTestowa" }],
    photoGallery: [
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/1/1/9/a/119ad993d31d92c1fbe64f97215de64e9791bc43_PL_8001090207418_1.jpg",
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/1/1/9/a/119ad993d31d92c1fbe64f97215de64e9791bc43_PL_8001090207418_1.jpg",
    ],
    wishlist: { base: true, concept: false },
    axis: "Test4",
    variants: [
      {
        sku: "6537452",
        attribute: {
          value: "Variant1",
          label: "Variant1",
        },
        order: 1,
        inStock: true,
        deliveryTime: null,
        enabled: true,
        productUrl:
          "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      },
      {
        sku: "123321123",
        attribute: {
          value: "Variant2",
          label: "Variant2",
        },
        order: 1,
        inStock: true,
        deliveryTime: null,
        enabled: true,
        productUrl:
          "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      },
      {
        sku: "123321123",
        attribute: {
          value: "Variant2",
          label: "Variant2",
        },
        order: 1,
        inStock: true,
        deliveryTime: null,
        enabled: true,
        productUrl:
          "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      },
      {
        sku: "123321123",
        attribute: {
          value: "Variant2",
          label: "Variant2",
        },
        order: 1,
        inStock: true,
        deliveryTime: null,
        enabled: true,
        productUrl:
          "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      },
      {
        sku: "123321123",
        attribute: {
          value: "Variant2",
          label: "Variant2",
        },
        order: 1,
        inStock: true,
        deliveryTime: null,
        enabled: true,
        productUrl:
          "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      },
      {
        sku: "123321123",
        attribute: {
          value: "Variant2",
          label: "Variant2",
        },
        order: 1,
        inStock: true,
        deliveryTime: null,
        enabled: true,
        productUrl:
          "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      },
    ],
    Brand: {
      value: "asd",
      label: "Ariel",
    },
    BasePrice: {
      value: "19.2300",
      label: null,
    },
    CompletionInformation: {
      value: "asssd",
      label:
        "Attribute PortalProducer is empty\nAttribute PortalLongName is empty",
    },
    MaterialID: {
      value: "asssdsss",
      label: "1001001199",
    },

    Producer: {
      value: "assasdassd",
      label: "A20",
    },
    DeliveryQuantity: {
      value: "sssdd",
      label: "4",
    },
    PortalDisplayOrder: {
      value: "ddd",
      label: "9999",
    },
    FullName: {
      value: "asffffssd",
      label: "ARIEL Płyn Touch of Lenor 2,64L",
    },
    country_of_origin: {
      value: "fffff",
      label: "PCE",
    },
    ItemCategoryGroup: {
      value: "gggggg",
      label: "Płyny do prania",
    },
    BaseUnitOfMeasure: {
      value: "hhhhh",
      label: "NORM",
    },
    Stock: {
      value: "jjjjj",
      label: "878.0000",
    },
  },
  {
    sku: "1001000145",
    type: TSrpProductType.PRODUCT,
    conceptId: null,

    badges: [
      {
        id: 6,
        name: "Promotion",
        badgeKind: BadgeType.PROMOTION,
      },
      {
        id: 5,
        name: "New",
        badgeKind: BadgeType.NEW,
      },
    ],
    coverPhoto:
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/e/b/7/9/eb794c1ed58ca6ea840067437eb09b57e2e31705_PL_8001090206930_1.jpg",
    hoverPhoto:
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
    deliveryTime: DeliveryTimeCode.DAYS_4_5,
    name: "LENOR Sensitive, płyn do płukania, 930 ml",
    price: {
      basePrice: 9.7,
      promotionPrice: null,
      netPrice: 10,
      grossPrice: 12,
    },
    tax: 50,
    rating: {
      numberOfReviews: 0,
      rate: null,
    },
    url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
    wishlist: { base: false, concept: false },
    axis: "Brand",
    variants: [
      {
        sku: "6537452",
        attribute: {
          value: "Brand",
          label: "Brand",
        },
        order: 1,
        inStock: false,
        deliveryTime: null,
        enabled: true,
        productUrl: "",
      },
      {
        sku: "123321123",
        attribute: {
          value: "Belutti",
          label: "Belutti",
        },
        order: 1,
        inStock: false,
        deliveryTime: null,
        enabled: true,
        productUrl: "",
      },
    ],

    associations: {},
  },
  {
    sku: "1001000685",
    conceptId: null,
    type: TSrpProductType.PRODUCT,

    badges: [],
    coverPhoto:
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/d/3/1/5/d3150984a5cf89a5861550219032b8879e2bfb34_PL_8001090791313_1.jpg",
    hoverPhoto:
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
    deliveryTime: DeliveryTimeCode.DAYS_4_5,
    name: "ARIEL Sensitive, płyn do prania, 2.2 l",
    price: {
      basePrice: 39.66,
      promotionPrice: null,
      netPrice: 10,
      grossPrice: 12,
    },
    tax: 50,
    rating: {
      numberOfReviews: 0,
      rate: null,
    },
    url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
    wishlist: { base: true, concept: false },
    axis: "Brand",
    variants: [
      {
        sku: "6537452",
        attribute: {
          value: "Brand",
          label: "Brand",
        },
        order: 1,
        inStock: false,
        deliveryTime: null,
        enabled: true,
        productUrl: "",
      },
      {
        sku: "123321123",
        attribute: {
          value: "Belutti",
          label: "Belutti",
        },
        order: 1,
        inStock: false,
        deliveryTime: null,
        enabled: true,
        productUrl: "",
      },
    ],

    associations: {},
  },
  {
    sku: "1001001059",
    conceptId: null,
    type: TSrpProductType.PRODUCT,

    badges: [],
    coverPhoto:
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/0/a/3/9/0a39bc050d6e34b1b8a37e32b5447ba89a19b822_PL_8001841163666_1.jpg",
    hoverPhoto:
      "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
    deliveryTime: DeliveryTimeCode.DAYS_4_5,
    name: "LENOR Sensitive, płyn do płukania, 2.91 l",
    price: {
      basePrice: 24.81,
      promotionPrice: null,
      netPrice: 10,
      grossPrice: 12,
    },
    tax: 50,
    rating: {
      numberOfReviews: 0,
      rate: null,
    },
    url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
    wishlist: { base: true, concept: false },
    axis: "Brand",
    variants: [
      {
        sku: "6537452",
        attribute: {
          value: "Brand",
          label: "Brand",
        },
        order: 1,
        inStock: false,
        deliveryTime: null,
        enabled: true,
        productUrl: "",
      },
      {
        sku: "123321123",
        attribute: {
          value: "Belutti",
          label: "Belutti",
        },
        order: 1,
        inStock: false,
        deliveryTime: null,
        enabled: true,
        productUrl: "",
      },
    ],

    associations: {},
  },
];

export const SEARCH_PRODUCTS: ISrpResponse[] = [
  {
    sku: "1001000171",
    associations: { TestLabel: ["1001000145"] },
    type: TSrpProductType.PRODUCT,
    conceptId: null,

    srpInfo: {
      badges: [
        {
          id: 4,
          name: "BestsellerBadgeTest",
          badgeKind: BadgeType.BESTSELLER,
        },
        {
          id: 6,
          name: "PromotionBadgeTest",
          badgeKind: BadgeType.PROMOTION,
        },
        {
          id: 5,
          name: "New",
          badgeKind: BadgeType.NEW,
        },
      ],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/1/1/9/a/119ad993d31d92c1fbe64f97215de64e9791bc43_PL_8001090207418_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: DeliveryTimeCode.DAYS_4_5,
      name: "LENOR Sensitive, płyn do płukania, 1360 ml",
      price: {
        basePrice: 16.3,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 322,
        rate: 1.4,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      pools: [{ id: 1, name: "PulaTestowa" }],
      photoGallery: [
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/1/1/9/a/119ad993d31d92c1fbe64f97215de64e9791bc43_PL_8001090207418_1.jpg",
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/1/1/9/a/119ad993d31d92c1fbe64f97215de64e9791bc43_PL_8001090207418_1.jpg",
      ],
      wishlist: { base: true, concept: false },
      axis: "Test4",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Variant1",
            label: "Variant1",
          },
          order: 1,
          inStock: true,
          deliveryTime: null,
          enabled: true,
          productUrl:
            "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Variant2",
            label: "Variant2",
          },
          order: 1,
          inStock: true,
          deliveryTime: null,
          enabled: true,
          productUrl:
            "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Variant2",
            label: "Variant2",
          },
          order: 1,
          inStock: true,
          deliveryTime: null,
          enabled: true,
          productUrl:
            "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Variant2",
            label: "Variant2",
          },
          order: 1,
          inStock: true,
          deliveryTime: null,
          enabled: true,
          productUrl:
            "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Variant2",
            label: "Variant2",
          },
          order: 1,
          inStock: true,
          deliveryTime: null,
          enabled: true,
          productUrl:
            "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Variant2",
            label: "Variant2",
          },
          order: 1,
          inStock: true,
          deliveryTime: null,
          enabled: true,
          productUrl:
            "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    attributes: {
      sku: {
        value: "1001000171",
        label: "1001000171",
      },
      Brand: {
        value: "asd",
        label: "Ariel",
      },
      BasePrice: {
        value: "19.2300",
        label: null,
      },
      CompletionInformation: {
        value: "asssd",
        label:
          "Attribute PortalProducer is empty\nAttribute PortalLongName is empty",
      },
      MaterialID: {
        value: "asssdsss",
        label: "1001001199",
      },

      Producer: {
        value: "assasdassd",
        label: "A20",
      },
      DeliveryQuantity: {
        value: "sssdd",
        label: "4",
      },
      PortalDisplayOrder: {
        value: "ddd",
        label: "9999",
      },
      FullName: {
        value: "asffffssd",
        label: "ARIEL Płyn Touch of Lenor 2,64L",
      },
      country_of_origin: {
        value: "fffff",
        label: "PCE",
      },
      ItemCategoryGroup: {
        value: "gggggg",
        label: "Płyny do prania",
      },
      BaseUnitOfMeasure: {
        value: "hhhhh",
        label: "NORM",
      },
      Stock: {
        value: "jjjjj",
        label: "878.0000",
      },
    },
  },
  {
    sku: "1001000145",
    type: TSrpProductType.PRODUCT,
    conceptId: null,
    srpInfo: {
      badges: [
        {
          id: 6,
          name: "Promotion",
          badgeKind: BadgeType.PROMOTION,
        },
        {
          id: 5,
          name: "New",
          badgeKind: BadgeType.NEW,
        },
      ],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/e/b/7/9/eb794c1ed58ca6ea840067437eb09b57e2e31705_PL_8001090206930_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: DeliveryTimeCode.DAYS_4_5,
      name: "LENOR Sensitive, płyn do płukania, 930 ml",
      price: {
        basePrice: 9.7,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: false, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1001000685",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/d/3/1/5/d3150984a5cf89a5861550219032b8879e2bfb34_PL_8001090791313_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: DeliveryTimeCode.DAYS_4_5,
      name: "ARIEL Sensitive, płyn do prania, 2.2 l",
      price: {
        basePrice: 39.66,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1001001059",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/0/a/3/9/0a39bc050d6e34b1b8a37e32b5447ba89a19b822_PL_8001841163666_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: DeliveryTimeCode.DAYS_4_5,
      name: "LENOR Sensitive, płyn do płukania, 2.91 l",
      price: {
        basePrice: 24.81,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1001001110",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/c/8/8/6/c886b143ae8edc7ee66123c6a1264380c3054694_PL_8001841375564_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: DeliveryTimeCode.DAYS_4_5,
      name: "LENOR Sensitive, płyn do płukania, 1800 ml",
      price: {
        basePrice: 21.52,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1001001200",
    type: TSrpProductType.PRODUCT,
    conceptId: null,
    srpInfo: {
      badges: [],
      coverPhoto: null,
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: null,
      price: {
        basePrice: 50.49,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1001001334",
    type: TSrpProductType.PRODUCT,
    conceptId: null,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/0/4/f/5/04f5e693a36538fcbf020d0ddc447df2f6b1a294_PL_4084500583115_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: DeliveryTimeCode.DAYS_4_5,
      name: "FAIRY Sensitive, płyn do mycia naczyń, 5 l",
      price: {
        basePrice: 35.21,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1001001976",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto: null,
      deliveryTime: null,
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      name: null,
      price: {
        basePrice: 39.66,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1001001977",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/4/8/0/0/4800035717af8ab5e5a9f014d34d87e94dff6276_PL_8001841597928_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "ARIEL Sensitive, kapsułki do prania, 33 szt.",
      price: {
        basePrice: 50.49,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1001002015",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto: null,
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: null,
      price: {
        basePrice: 30.82,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1001002019",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/0/a/2/d/0a2dd2538f7c08672c104008f68af1f0497cca9e_PL_8001841693804_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "VIZIR Sensitive, proszek do prania, 3.83 kg",
      price: {
        basePrice: 47.27,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1001002101",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto: null,
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: null,
      price: {
        basePrice: 30.1,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1001002631",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/8/5/b/585b378d60f0418ac16be8cfa8f647a7e6aa943c_PL_8006540115374_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "ARIEL Sensitive, kapsułki do prania, 26 szt.",
      price: {
        basePrice: 39.66,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: false, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1001002635",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/a/f/0/2/af02160bed674b2402c16cfbc1764caf327ec592_PL_8006540117125_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "ARIEL Sensitive, kapsułki do prania, 13 szt.",
      price: {
        basePrice: 20.19,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: false, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1001002645",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/1/f/e/3/1fe3671c81789b2bc9e00f85afe85737ab2a75d0_PL_8006540209561_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "ARIEL Sensitive, płyn do prania, 1.93 l",
      price: {
        basePrice: 39.66,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: false, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1001002646",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/1/7/e/b/17ebbfaede3bed06460b8b4a8bd4cff3148084df_PL_8006540120217_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "ARIEL Sensitive, płyn do prania, 2.31 l",
      price: {
        basePrice: 50.51,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: false, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1001002647",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/7/d/8/1/7d8166ff351cf2f6a405d303339bb16e87846ba5_PL_8006540120293_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "ARIEL Sensitive, płyn do prania, 3.41 l",
      price: {
        basePrice: 65.25,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1002000098",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/1/f/9/3/1f938f473d006c88d3bb97a051b7a5d1783d267e_PL_5011321336124_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "HEAD&SHOULDERS Sensitive, szampon, 400 ml",
      price: {
        basePrice: 24.25,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1002000100",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/d/6/5/9/d65939844a9adec6c11c7cad57805f36f622e2b0_PL_4084500105690_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "HEAD&SHOULDERS Sensitive, szampon, 250 ml",
      price: {
        basePrice: 11.78,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1002000399",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/0/3/5/f/035f784499e89458376da41a11802d721f12abed_PL_7702018037896_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "GILLETTE Match 3 Sensitive, wkłady do maszynki, 4 szt.",
      price: {
        basePrice: 42.79,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: false, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1002001662",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/0/3/b/7/03b7f66ead802884ae0694f9c96ab54a11b69637_PL_7702018490646_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "GILLETTE Blue 3 Sensitive, maszynki jednorazowe, 12 szt.",
      price: {
        basePrice: 51.75,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: false, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1002002021",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/3/d/a/1/3da1e6cd19c72910fffbea1abfdaa9d315657b00_PL_7702018490134_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "GILLETTE Blue 3 Sensitive, maszynki jednorazowe, 6 szt.",
      price: {
        basePrice: 31.49,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: false, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1002002022",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/c/9/7/e/c97ef5fad753320e22ebdb99238479b366730b0b_PL_7702018505678_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "GILLETTE Blue 3 Sensitive, maszynki jednorazowe, 8 szt.",
      price: {
        basePrice: 36.29,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: false, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1002003028",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "GILLETTE Sensor3 Sensitive, wkłady do maszynki, 8 szt.",
      price: {
        basePrice: 32.12,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1002003030",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/c/8/a/e/c8aec70804fc3df3c0a525d9c1502680a2f26202_PL_7702018589043_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "GILLETTE Sensor3 Sensitive 1+6, maszynka do golenia, 6 wkł.",
      price: {
        basePrice: 32.12,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl:
            "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl:
            "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "123",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/c/8/a/e/c8aec70804fc3df3c0a525d9c1502680a2f26202_PL_7702018589043_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "GILLETTE Sensor3 Sensitive 1+6, maszynka do golenia, 6 wkł.",
      price: {
        basePrice: 32.12,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "678",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/c/8/a/e/c8aec70804fc3df3c0a525d9c1502680a2f26202_PL_7702018589043_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "GILLETTE Sensor3 Sensitive 1+6, maszynka do golenia, 6 wkł.",
      price: {
        basePrice: 32.12,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1002003023",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "GILLETTE Sensor3 Sensitive, wkłady do maszynki, 8 szt.",
      price: {
        basePrice: 32.12,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1002000400",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/d/6/5/9/d65939844a9adec6c11c7cad57805f36f622e2b0_PL_4084500105690_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "HEAD&SHOULDERS Sensitive, szampon, 250 ml",
      price: {
        basePrice: 11.78,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1002000101",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/1/f/9/3/1f938f473d006c88d3bb97a051b7a5d1783d267e_PL_5011321336124_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "HEAD&SHOULDERS Sensitive, szampon, 400 ml",
      price: {
        basePrice: 24.25,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1002000800",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/d/6/5/9/d65939844a9adec6c11c7cad57805f36f622e2b0_PL_4084500105690_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "HEAD&SHOULDERS Sensitive, szampon, 250 ml",
      price: {
        basePrice: 11.78,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 12,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: true, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537452",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321123",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
  {
    sku: "1002000354",
    conceptId: null,
    type: TSrpProductType.PRODUCT,
    srpInfo: {
      badges: [],
      coverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/0/3/5/f/035f784499e89458376da41a11802d721f12abed_PL_7702018037896_1.jpg",
      hoverPhoto:
        "https://dev-minio-ecp.nd0.pl/cms-pim-dev/5/b/2/7/5b270817c35589c3a268dd92c8ba92cb7b0e321f_PL_7702018589029_1.jpg",
      deliveryTime: null,
      name: "GILLETTE Match 3 Sensitive, wkłady do maszynki, 4 szt.",
      price: {
        basePrice: 42.79,
        promotionPrice: null,
        netPrice: 10,
        grossPrice: 10,
      },
      tax: 50,
      rating: {
        numberOfReviews: 0,
        rate: null,
      },
      url: "/p/gillette-sensor3-sensitive-1-6-maszynka-do-golenia-6-wkl-1002003030",
      wishlist: { base: false, concept: false },
      axis: "Brand",
      variants: [
        {
          sku: "6537654",
          attribute: {
            value: "Brand",
            label: "Brand",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
        {
          sku: "123321543",
          attribute: {
            value: "Belutti",
            label: "Belutti",
          },
          order: 1,
          inStock: false,
          deliveryTime: null,
          enabled: true,
          productUrl: "",
        },
      ],
      deliveryQuantity: 1,
      mainUnitOfMeasurement: "piece",
      mainUnitOfMeasurementLabel: "piece",
      alternativeUnitOfMeasures: [],
      concession: true,
    },
    associations: {},
  },
];

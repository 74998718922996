import { TUrl } from "./themeSettings/themeSettings.types";

export enum NotificationErrorB2B_Cart {
  PRODUCT_IS_NOT_AVAILABILITY = "PRODUCT_IS_NOT_AVAILABILITY",
  PRODUCT_IS_PARTIALLY_AVAILABILITY = "PRODUCT_IS_PARTIALLY_AVAILABILITY",
  CREDIT_LIMIT_IS_NOT_ENABLED = "CREDIT_LIMIT_IS_NOT_ENABLED",
  CREDIT_LIMIT_EXCEEDED = "CREDIT_LIMIT_EXCEEDED",
  DELIVERY_DATE_CHANGED = "DELIVERY_DATE_CHANGED",
  DELIVERY_DATE_NOT_AVAILABLE = "DELIVERY_DATE_NOT_AVAILABLE",
  CONTRACTOR_HAS_NO_CREDIT_LIMIT = "CONTRACTOR_HAS_NO_CREDIT_LIMIT",
  CONTRACTOR_HAS_NO_DELIVERY_DATE_AVAILABLE = "CONTRACTOR_HAS_NO_DELIVERY_DATE_AVAILABLE",
  NO_CONCESSION = "NO_CONCESSION",
  CONTRACTOR_STATUS_BLOCKED = "CONTRACTOR_STATUS_BLOCKED",
  CONTRACTOR_STATUS_LIMITED = "CONTRACTOR_STATUS_LIMITED",
}

export enum PaymentMethodOptionB2B {
  CASH = "CASH",
  CREDIT = "CREDIT",
}

export enum LimitedClientStatus {
  ACTIVE = "ACTIVE",
  LIMITED = "LIMITED",
  BLOCKED = "BLOCKED",
}

export enum CreditLimitType {
  NET = "NET",
  GROSS = "GROSS",
}

export interface IPriceDetailsB2B {
  netValue: number;
  grossValue: number;
}

export interface ICartLineB2B {
  sku: string;
  name: string;
  photoUrl: string;
  quantity: number;
  deliveryQuantity: number;
  stock: number | null; //null if stock verification is disabled
  unitOfMeasure: IUnitOfMeasure;
  productPriceDetails: IPriceDetailsB2B;
  notificationErrors: {
    data: string;
    type: NotificationErrorB2B_Cart;
  }[];
}

export interface IAvailableUnitsOfMeasure {
  unitOfMeasure: string;
  unitOfMeasureQuantity: number;
  unitOfMeasureLabel: string;
}
export interface IUnitOfMeasure {
  currentUnitOfMeasure: string;
  baseUnitOfMeasure: string;
  availableUnitsOfMeasure: IAvailableUnitsOfMeasure[];
}

export interface IPaymentMethodOptionB2B {
  active: boolean;
  portalPaymentMethod: PaymentMethodOptionB2B;
}

export interface IDeliveryAddressB2B {
  name: string;
  apartmentNo: string;
  city: string;
  country: string;
  houseNo: string;
  postCode: string;
  province: string;
  street: string;
}

interface IDeliveryCalendarB2B_DTO {
  deliveryDate: string;
  enabled: boolean;
}

interface IOrderB2B_DTO {
  productType: string;
  netLogisticMin: number;
  netLogisticMinMissing: number;
  deliveryCalendar: IDeliveryCalendarB2B_DTO[];
  deliveryDate: string | null;
  cartLines: ICartLineB2B[];
}

export interface ICartB2B_DTO {
  orders: IOrderB2B_DTO[];
  summary: ISummaryB2B;
  notificationErrors: {
    data: string;
    type: NotificationErrorB2B_Cart;
  }[];
  stockVerification: boolean;
  hasNotificationErrors: boolean;
  cartId: string;
}

export interface IDeliveryCalendarB2B {
  deliveryDate: Date;
  enabled: boolean;
}

export interface IOrderB2B {
  productType: string;
  netLogisticMin: number;
  netLogisticMinMissing: number;
  deliveryCalendar: IDeliveryCalendarB2B[];
  deliveryDate: Date | null;
  cartLines: ICartLineB2B[];
}

export interface ICartB2B {
  orders: IOrderB2B[];
  summary: ISummaryB2B;
  notificationErrors: NotificationErrorB2B_Cart[];
  unavailableProducts: string[]; //list of skus
  stockVerification: boolean;
  hasNotificationErrors: boolean;
  cartId: string;
}

export interface AddToCartB2B2CRequest {
  sku: string;
  quantity: number;
  unitOfMeasure?: string; //required in B2B add to cart
  isCart: boolean;
}

export interface DeleteProductRequest {
  sku: string;
  productType: string;
}

export interface SetDeliveryDateB2BRequest {
  productType: string;
  deliveryDate: string;
}

export interface SetPaymentMethodB2BRequest {
  contractorPaymentMethod: PaymentMethodOptionB2B;
}

export interface IChangeQuantityProduct {
  sku: string;
  quantity: number;
  deliveryQuantity: number;
  stock: number;
  unitOfMeasure: IUnitOfMeasure;
}

export interface ISummaryB2B {
  netTotalCartValue: number;
  grossTotalCartValue: number;
  creditLimitAvailable: number;
  creditLimitExceeded: number;
  totalCreditLimit: number;
  creditLimitType: CreditLimitType;
  paymentMethodOptions: IPaymentMethodOptionB2B[];
  deliveryAddress: IDeliveryAddressB2B;
  paymentMethod: PaymentMethodOptionB2B;
  limitedClientStatus: LimitedClientStatus;
}

export interface ICartB2B {
  orders: IOrderB2B[];
  summary: ISummaryB2B;
  notificationErrors: NotificationErrorB2B_Cart[];
  stockVerification: boolean;
}

export interface PutPlaceOrderB2BResponse {
  cart: ICartB2B_DTO;
  cartId: string;
}

interface IProductSummaryB2B {
  sku: string;
  grossValue: number;
  netValue: number;
  quantity: number;
  totalNetValue: number;
  totalGrossValue: number;
  name: string;
  coverPhoto: TUrl | null;
  unitOfMeasurementLabel: string | null;
}

export interface IOrderSummaryB2B {
  orderId: string;
  netValue: number;
  grossValue: number;
  creationDate: string;
  paymentForm: PaymentMethodOptionB2B;
  promiseDeliveryDate: string;
  orderPositions: IProductSummaryB2B[];
}

export interface ISummaryB2B_StepTwo {
  contractorOrderResponseList: IOrderSummaryB2B[];
}

export interface IMiniCartB2BResponseDTO {
  orders: IOrderB2B[];
  summary: ISummaryB2B;
}

export interface IMiniCartB2BResponse extends IMiniCartB2BResponseDTO {
  count: number;
}

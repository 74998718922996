import {
  IArticleCategoriesResponse,
  IArticlesResponse,
} from "../../api/articles";

export const ARTICLE_MOCK: IArticlesResponse = {
  result: [
    {
      articleUrl: "/porady/oiuio,1.html",
      categories: [
        {
          id: 2,
          name: "Porady",
          special: false,
        },
      ],
      content: `<h2>dsfsfsdfsd</h2><h3>fsdfsdfsdf</h3><h4>sdfsdfsdfsdf</h4><p>sdfsdfsdfdsf</p><ul><li>aaaa</li><li>ssss</li><li>dddd</li></ul><p>&nbsp;</p><ol><li>sdsda</li><li>asdasdas</li><li>asdasssd</li></ol><figure class="image"><img src="https://dev-minio-ecp.nd0.pl/ecp-media-cms/elektronika.jpg"></figure>`,
      createdBy: "admin@9bits.pl",
      creationDate: "2022-09-22T14:57:44.817369Z",
      description: "testdesc testdesc testdesc testdesc ",
      editableArticleUrlPart: "oiuio",
      id: 1,
      imageUrl: "https://dev-minio-ecp.nd0.pl/ecp-media-cms/laptop.png",
      keywords: "",
      metaDescription: "",
      metaTitle: "",
      modificationDate: "2022-09-22T14:58:59.415544Z",
      modifiedBy: "admin@9bits.pl",
      relatedArticlesIds: [2],
      relatedProductCategoriesCodes: ["1", "2", "3"],
      relatedProductsSku: [],
      status: "DRAFT",
      title: "test test test test ",
    },
  ],
  page: {
    number: 1,
    size: 25,
    count: 6,
    total: 6,
  },
};

export const ARTICLE_CATEGORIES_MOCK: IArticleCategoriesResponse = {
  result: [
    {
      id: 1,
      name: "Kategoria",
      special: false,
    },
    {
      id: 2,
      name: "Porady",
      special: false,
    },
  ],
  page: {
    number: 1,
    size: 25,
    count: 6,
    total: 6,
  },
};
